import React from "react";
import Layout from "../layout";
import Seo from "../components/Seo/Seo";
import Valeurs from "../components/Valeurs/Valeurs";
import Domaines from "../components/Domaines/Domaines";
import Contact from "../components/Contact/Contact";
import config from "../../data/SiteConfig";
import { graphql } from "gatsby";
import GImage from "../components/Picture/GImage";

const PresentationPage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  return (
    <Layout>
      <Seo
        title={`Présentation`}
        description={config.siteDescription2}
        article={false}
      />
      <div className="h-20 md:h-28"></div>
      {directus.presentation && directus.presentation.illustration !== null && (
        <GImage
          className="h-44 md:h-72 bg-presentation bg-cover"
          src={directus.presentation.illustration.imageFile}
          alt="Image de la page présentation du secteur langues du Groupe Français d'éducation Nouvelle"
        />
      )}
      <div className="presentation-container pt-8">
        {directus.presentation && (
          <h1 className="text-center pt-6 mb-8 px-3 sm:px-6">
            {directus.presentation.title}
          </h1>
        )}
        <hr className="bg-red-500 border-none h-2" />
        <div className="max-w-6xl mx-auto pt-0 pb-10 px-3 sm:px-6">
          {directus.presentation && directus.presentation.intro && (
            <div
              className="mt-6 mb-10 text-editor"
              dangerouslySetInnerHTML={{
                __html: `${directus.presentation.intro}`,
              }}
            />
          )}
        </div>
        {directus.presentation && (
          <Domaines presentation={directus.presentation} />
        )}
        <div className="max-w-6xl mx-auto pt-6 pb-10 px-3 sm:px-6">
          {directus.presentation && directus.presentation.description && (
            <div
              className="mt-6 mb-10 text-editor"
              dangerouslySetInnerHTML={{
                __html: `${directus.presentation.description}`,
              }}
            />
          )}
        </div>
        {directus.presentation && (
          <Valeurs presentation={directus.presentation} />
        )}
        <div className="max-w-6xl mx-auto pt-6 pb-20 px-3 sm:px-6">
          {directus.presentation && directus.presentation.description2 && (
            <div
              className="mt-6 mb-10 text-editor"
              dangerouslySetInnerHTML={{
                __html: `${directus.presentation.description2}`,
              }}
            />
          )}
        </div>
        {directus.contact && (
          <Contact contact={directus.contact} background={true} />
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      presentation {
        title
        intro
        description
        description2
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
        title_valeurs
        subtitle_valeurs
        descriptions_valeurs
        illustration_valeurs {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
        title_domaines
        intro_domaines
        descriptions_domaines
        illustration_domaines {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
      contact {
        title
        subtitle
        intro
        informations
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
    }
  }
`;

export default PresentationPage;

// # slides(sort: ["sort"]) {
// #   id
// #   directus_files_id {
// #     id
// #     filename_disk
// #     imageFile {
// #       childImageSharp {
// #         gatsbyImageData(
// #           quality: 80
// #           placeholder: TRACED_SVG
// #           tracedSVGOptions: { color: "#f3f3ed" }
// #           layout: FULL_WIDTH
// #           formats: WEBP
// #         )
// #       }
// #     }
// #   }
// # }
// # grappe: file(relativePath: { eq: "nos-vins/grappe.jpg" }) {
// #   childImageSharp {
// #     gatsbyImageData(
// #       layout: CONSTRAINED
// #       quality: 80
// #       placeholder: TRACED_SVG
// #       tracedSVGOptions: { color: "#f3f3ed" }
// #       formats: WEBP
// #     )
// #   }
// # }
